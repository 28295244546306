import React from "react";
import Bar from "./bar";

const Nav = () => {
  return (
    <>
      <Bar />
    </>
  );
};

export default Nav;
